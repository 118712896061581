<template>
  <div class="clinicVerify-box">
    <nav-bar :title="$t('診所信息')" :isFixed="true" :goBack="goBack" :bgc="'background-color: #FFFFFF;'"></nav-bar>
    <div
      :class="['status',userInfo.authStatus === '0' ? 'orange-bg' : userInfo.authStatus === '1' ? 'green-bg' : 'red-bg']">
      {{ $t(getStatus) }}<span v-if="userInfo.authStatus === '2'">:{{ userInfo.reason }}</span>
    </div>
    <div class="box-main">
      <div class="title">{{ $t('診所名稱') }}</div>
      <van-field v-model="params.companyName" :disabled="userInfo.authStatus == 0 || userInfo.authStatus == 1"
        :class="{ 'gray': userInfo.authStatus == 0 || userInfo.authStatus == 1 }" type="text"
        :placeholder="$t('请填写診所名稱')" />
      <div class="title" style="margin-top: 24px;margin-bottom: 16px;">{{ $t('商業登記證') }}</div>
      <div class="business-img" v-if="!fileList.length">
        <img class="img"  :src="BASE_IMG + userInfo.businessLicense" alt="" @error="handleImageError" />
        <img @click.stop="openBigimg" class="amplify" src="@/assets/icons/amplify.png">
      </div>
      <div class="upload" v-if="userInfo.authStatus === '2'">
        <van-uploader :max-count="1" :before-read="beforeRead" 
          :after-read="afterRead" v-model="fileList" preview-size="100%">
          <template #default>
            <div class="changeBtn" >{{ $t('更換營業執照') }}</div>
          </template>
        </van-uploader>
      </div>
      <div class="footer" v-if="userInfo.authStatus!='1'">
        <van-button v-if="userInfo.authStatus === '0'" disabled color="#90D12E" block type="primary">
          {{ $t('審核中不可修改') }}
        </van-button>
        <van-button v-else color="#90D12E" block type="primary" @click="handleSubmit">
          {{ $t('提交') }}
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex"
import { userAuthNew } from "@/api/user"
import Config from "@/utils/config.js"
import { ImagePreview } from "vant"
import mixins from "@/utils/mixins.js"

export default {
  name: "clinicVerify",
  mixins: [mixins],
  data () {
    return {
      params: {
        companyName: "",
        businessLicense: ""
      },
      BASE_IMG: Config.api,
      fileList: [],
    }
  },
  async mounted () {
    await this.$store.dispatch("user/getUserInfo")
    this.params.companyName = this.userInfo.companyName
    this.params.businessLicense = this.userInfo.businessLicense
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    getStatus () {
      switch (this.userInfo.authStatus) {
        case '0':
          return '審核中'
        case '1':
          return '认证已通過'
        case '2':
          return '未通過'
        case '4':
          return '暫未認證'
        default:
          return ''
      }
    }
  },
  methods: {
    async handleSubmit () {
      let formData = new FormData()
      Object.entries({ ...this.params }).forEach(([key, value]) => {
        formData.append(key, value)
      })
      const res = await userAuthNew(formData)
      if (res.resultID === 1200) {
        this.$toast(this.$t('操作成功'))
        this.$router.back()
      }
    },
    async beforeRead (file) {
      const maxSize = 10 * 1024 * 1024
      const maxSize2 = 5 * 1024 * 1024

      this.loading2 = true
      // 文件类型检查
      // if (!file.type?.includes('image')) {
      // this.loading2=false;
      //   this.$toast("请上传图片");
      //   return false;
      // }
      if (file.size > maxSize) {
        this.$toast("文件體積過大,請小于10mb")
        return false
      }
      if (file.type.includes('heic')) {
        const resultBlob = await heic2any({
          blob: file,
          toType: "image/jpeg"
        })
        file = new File([resultBlob], 'newFileName.' + file.name.split('.').pop(), { type: file.type })
      }
      return this.compressImg(file)
        .then(compressedFile => {
          return compressedFile // 返回压缩后的文件
        })
        .catch(err => {
          console.error(err)
          return false // 处理错误情况
        })

    },
    afterRead (file) {
      this.params.businessLicense = file.file
      this.loading2 = false
      this.$nextTick(() => {
        this.fileList = [{ ...file, isImage: true }]
      })
    },
    compressImg (fileObj) {
      return new Promise((resolve, reject) => {
        let quality = 0.99
        let reader = new FileReader()
        reader.readAsDataURL(fileObj)
        reader.onload = function (e) {
          let image = new Image()
          image.src = e.target.result
          image.onload = function () {
            let canvas = document.createElement('canvas')
            let context = canvas.getContext('2d')
            let imageWidth = image.width / 2
            let imageHeight = image.height / 2
            canvas.width = imageWidth
            canvas.height = imageHeight

            context.drawImage(image, 0, 0, imageWidth, imageHeight)
            let data = canvas.toDataURL('image/jpg', quality)
            while (data.length / 1024 / 1024 > 5) {
              quality -= 0.03
              context.clearRect(0, 0, canvas.width, canvas.height) // 清空画布
              context.drawImage(image, 0, 0, canvas.width, canvas.height) // 重新绘制图像
              data = canvas.toDataURL("image/jpeg", quality) // 重新生成数据URL
            }

            canvas.toBlob(function (blob) {
              const f = new File([blob], fileObj.name, { type: 'image/jpeg' })
              resolve(f)
            }, 'image/jpeg')
          }
          image.onerror = function () {
            reject(new Error("Image loading error"))
          }
        }
        reader.onerror = function () {
          reject(new Error("File reading error"))
        }
      })
    },
    // 放大图片
    openBigimg () {
      const Src = this.BASE_IMG + this.userInfo.businessLicense
      ImagePreview({
        images: [Src],
        showIndex: false,
        loop: false
      })
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>
<style lang="scss" scoped>
.clinicVerify-box {
  padding-top: 44px;
  background: #FFFFFF;
  min-height: 100vh;

  .status {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 40px;
  }

  .red-bg {
    background: #FEF0F0;
    color: #F82207;
  }

  .orange-bg {
    background: #FAECD8;
    color: #FF9900;
  }

  .green-bg {
    background: #E6FBF1;
    color: #00D06D;
  }

  .box-main {
    padding: 24px 32px 0;

    .gray {
      background: #fbfbfb;
    }

    .title {
      font-weight: 500;
      font-size: 17px;
      color: #1A1A1A;
      line-height: 24px;

      &::after {
        content: "*";
        color: red;
        margin-left: 4px;
      }
    }

    :deep(.van-field) {
      border-bottom: 1px solid #F8F8F8;
    }

    .business-img {
      height: 216px;
      width: 312px;
      margin: 0 auto;
      position: relative;

      .img {
        width: 100%;
        height: 100%;
      }

      .amplify {
        position: absolute;
        right: 0;
        top: 0;
        width: 28px;
        height: 28px;
      }
    }

    .upload{
      margin: 16px auto 0;
      text-align: center;
    }
    .changeBtn {
      font-weight: 400;
      font-size: 16px;
      color: #90D12E;
      line-height: 24px;

    }

    .footer {
      margin-top: 100px;
    }

  }


}
</style>